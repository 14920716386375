<template>
  <b-card no-body class="round-set-numbers mb-1 position-relative">
    <b-card-header header-tag="nav">
      <h6>รายการเลขอั้น</h6>

      <b-nav card-header tabs>
        <b-nav-item @click="activeTab='ThreeNumbers'" :active="activeTab==='ThreeNumbers'">3 ตัว</b-nav-item>
        <b-nav-item @click="activeTab='TwoNumbers'" :active="activeTab==='TwoNumbers'">2 ตัว</b-nav-item>
        <b-nav-item @click="activeTab='OneNumbers'" :active="activeTab==='OneNumbers'">เลขวิ่ง</b-nav-item>
      </b-nav>
    </b-card-header>

    <div class="p-0">
      <table v-if="activeTab==='ThreeNumbers'" class="table table-sm table-bordered table-hover mb-0">
        <thead>
          <tr>
            <th rowspan="2" width="30%" class="text-center p-1">เลข</th>
            <th colspan="2" class="text-center p-1">เรทจ่าย (บาท)</th>
          </tr>
          <tr>
            <th width="35%" class="text-center p-1">3 ตัวบน</th>
            <th width="35%" class="text-center p-1">3 ตัวโต๊ด</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in threeNumbers" :key="item.number">
            <td class="text-center p-1">
              <span class="text-primary">{{ item.number }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.top === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.top | amountFormat }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.tode === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.tode === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.tode === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.tode === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.tode | amountFormat }}</span>
            </td>
          </tr>
          <tr v-if="!threeNumbers.length">
            <td colspan="3" class="text-center">ไม่มีเลขอั้น</td>
          </tr>
        </tbody>
      </table>

      <table v-if="activeTab==='TwoNumbers'" class="table table-sm table-bordered table-hover mb-0">
        <thead>
          <tr>
            <th rowspan="2" width="30%" class="text-center p-1">เลข</th>
            <th colspan="2" class="text-center p-1">เรทจ่าย (บาท)</th>
          </tr>
          <tr>
            <th width="35%" class="text-center p-1">2 ตัวบน</th>
            <th width="35%" class="text-center p-1">2 ตัวล่าง</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in twoNumbers" :key="item.number">
            <td class="text-center p-1">
              <span class="text-primary">{{ item.number }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.top === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.top | amountFormat }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.bot === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.bot === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.bot === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.bot === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.bot | amountFormat }}</span>
            </td>
          </tr>
          <tr v-if="!twoNumbers.length">
            <td colspan="3" class="text-center">ไม่มีเลขอั้น</td>
          </tr>
        </tbody>
      </table>

      <table v-if="activeTab==='OneNumbers'" class="table table-sm table-bordered table-hover mb-0">
        <thead>
          <tr>
            <th rowspan="2" width="30%" class="text-center p-1">เลข</th>
            <th colspan="2" class="text-center p-1">เรทจ่าย (บาท)</th>
          </tr>
          <tr>
            <th width="35%" class="text-center p-1">วิ่งบน</th>
            <th width="35%" class="text-center p-1">วิ่งล่าง</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in oneNumbers" :key="item.number">
            <td class="text-center p-1">
              <span class="text-primary">{{ item.number }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.top === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.top === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.top === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.top === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.top | amountFormat }}</span>
            </td>
            <td class="text-center p-1">
              <span v-if="item.bot === 'c'" class="badge badge-danger">ปิดรับ</span>
              <span v-else-if="item.bot === 'h'" class="badge badge-warning">จ่ายครึ่ง</span>
              <span v-else-if="item.bot === 'f'" class="badge badge-success">จ่ายเต็ม</span>
              <span v-else-if="item.bot === 's'" class="badge badge-secondary">เลขเต็ม</span>
              <span v-else class="text-danger">{{ item.bot | amountFormat }}</span>
            </td>
          </tr>
          <tr v-if="!oneNumbers.length">
            <td colspan="3" class="text-center">ไม่มีเลขอั้น</td>
          </tr>
        </tbody>
      </table>

    </div>
  </b-card>
</template>
<script>
import LotteryService from '@/services/LotteryService'
import LottoHelper from '@/helpers/lotto'

export default {
  name: 'RoundSetNumbers',
  props: ['data', 'soldoutNumbers', 'holdings', 'stepData'],
  data() {
    return {
      activeTab: 'TwoNumbers',
      // soldoutNumbers: {}
    }
  },
  computed: {
    round() {
      return this.data || null
    },
    rate() {
      return this.$store.state?.round?.rate?.openBets || {}
    },
    setNumbers() {
      return this.data?.setNumbers || {}
    },
    threeNumbers() {
      const threeNumberTop = {
        ...this.setNumbers?.threeNumberTop,
        ...this.soldoutNumbers?.threeNumberTop
      }
      const threeNumberTode = {
        ...this.setNumbers?.threeNumberTode,
        ...this.soldoutNumbers?.threeNumberTode
      }

      const topKeys = Object.keys(threeNumberTop)
      const todeKeys = Object.keys(threeNumberTode)
      const keys = _.sortBy(_.uniq(topKeys.concat(todeKeys)), (k)=>{
        return parseInt(k)
      })

      const rateTop = this.rate?.threeNumberTop?.pay || 0
      const rateTod = this.rate?.threeNumberTode?.pay || 0

      return keys.map((number) => {

        let payTop = ''
        if(threeNumberTop?.[number] === 's') {
          payTop = 's'
        }else
        if(threeNumberTop?.[number] === 'h') {
          payTop = (rateTop/2)
        }else
        if(threeNumberTop?.[number] === 'c') {
          payTop = 'c'
        }else
        if(threeNumberTop?.[number]) {
          payTop = rateTop < threeNumberTop[number] ? rateTop : threeNumberTop[number]
        }else{
          const cutRate = LottoHelper.cutRate('threeNumberTop', this.round, this.holdings, this.stepData, number)
          payTop = rateTop - cutRate
        }

        let payTod = ''
        if(threeNumberTode?.[number] === 's') {
          payTod = 's'
        }else
        if(threeNumberTode?.[number] === 'h') {
          payTod = (rateTod/2)
        }else
        if(threeNumberTode?.[number] === 'c') {
          payTod = 'c'
        }else
        if(threeNumberTode?.[number]){
          payTod = rateTod < threeNumberTode[number] ? rateTod : threeNumberTode[number]
        }else{
          const cutRate = LottoHelper.cutRate('threeNumberTode', this.round, this.holdings, this.stepData, number)
          payTod = rateTod - cutRate
        }

        return {
          number: number,
          top: this.rate?.threeNumberTop?.isAvailable ? payTop : 'c',
          tode: this.rate?.threeNumberTode?.isAvailable ? payTod : 'c'
        }
      })
    },
    twoNumbers() {
      const twoNumberTop = {
        ...this.setNumbers?.twoNumberTop,
        ...this.soldoutNumbers?.twoNumberTop
      }
      const twoNumberBottom = {
        ...this.setNumbers?.twoNumberBottom,
        ...this.soldoutNumbers?.twoNumberBottom
      }

      const topKeys = Object.keys(twoNumberTop)
      const botKeys = Object.keys(twoNumberBottom)
      const keys = _.sortBy(_.uniq(topKeys.concat(botKeys)), (k)=>{
        return parseInt(k)
      })

      const rateTop = this.rate?.twoNumberTop?.pay || 0
      const rateBot = this.rate?.twoNumberBottom?.pay || 0

      return keys.map((number) => {

        let payTop = ''
        if(twoNumberTop?.[number] === 's') {
          payTop = 's'
        }else
        if(twoNumberTop?.[number] === 'h') {
          payTop = (rateTop/2)
        }else
        if(twoNumberTop?.[number] === 'c') {
          payTop = 'c'
        }else
        if(twoNumberTop?.[number]) {
          payTop = rateTop < twoNumberTop[number] ? rateTop : twoNumberTop[number]
        }else{
          const cutRate = LottoHelper.cutRate('twoNumberTop', this.round, this.holdings, this.stepData, number)
          payTop = rateTop - cutRate
        }

        let payBot = ''
        if(twoNumberBottom?.[number] === 's') {
          payBot = 's'
        }else
        if(twoNumberBottom?.[number] === 'h') {
          payBot = (rateBot/2)
        }else
        if(twoNumberBottom?.[number] === 'c') {
          payBot = 'c'
        }else
        if(twoNumberBottom?.[number]) {
          payBot = rateBot < twoNumberBottom[number] ? rateBot : twoNumberBottom[number]
        }else{
          const cutRate = LottoHelper.cutRate('twoNumberBottom', this.round, this.holdings, this.stepData, number)
          payBot = rateBot - cutRate
        }

        return {
          number: number,
          top: this.rate?.twoNumberTop?.isAvailable ? payTop : 'c',
          bot: this.rate?.twoNumberBottom?.isAvailable ? payBot : 'c'
        }
      })
    },
    oneNumbers() {
      const runTop = {
        ...this.setNumbers?.runTop,
        ...this.soldoutNumbers?.runTop
      }
      const runBottom = {
        ...this.setNumbers?.runBottom,
        ...this.soldoutNumbers?.runBottom
      }

      const topKeys = Object.keys(runTop)
      const botKeys = Object.keys(runBottom)
      const keys = _.sortBy(_.uniq(topKeys.concat(botKeys)), (k)=>{
        return parseInt(k)
      })

      const rateTop = this.rate?.runTop?.pay || 0
      const rateBot = this.rate?.runBottom?.pay || 0

      return keys.map((number) => {

        let payTop = ''
        if(runTop?.[number] === 's') {
          payTop = 's'
        }else
        if(runTop?.[number] === 'h') {
          payTop = (rateTop/2)
        }else
        if(runTop?.[number] === 'c') {
          payTop = 'c'
        }else
        if(runTop?.[number]){
          payTop = rateTop < runTop[number] ? rateTop : runTop[number]
        }else{
          const cutRate = LottoHelper.cutRate('runTop', this.round, this.holdings, this.stepData, number)
          payTop = rateTop - cutRate
        }

        let payBot = ''
        if(runBottom?.[number] === 's') {
          payBot = 's'
        }else
        if(runBottom?.[number] === 'h') {
          payBot = (rateBot/2)
        }else
        if(runBottom?.[number] === 'c') {
          payBot = 'c'
        }else
        if(runBottom?.[number]){
          payBot = rateBot < runBottom[number] ? rateBot : runBottom[number]
        }else{
          const cutRate = LottoHelper.cutRate('runBottom', this.round, this.holdings, this.stepData, number)
          payBot = rateBot - cutRate
        }

        return {
          number: number,
          top: this.rate?.runTop?.isAvailable ? payTop : 'c',
          bot: this.rate?.runBottom?.isAvailable ? payBot : 'c'
        }
      })
    }
  },
  // methods: {
  //   getSoldout() {
  //     LotteryService.getRoundSoldout(this.$route.params.roundId)
  //     .then((response)=>{
  //       if(response.success) {
  //         this.soldoutNumbers = response.data
  //         this.$emit('soldout', response.data)
  //       }
  //     })
  //   }
  // },
  // mounted() {
  //   this.getSoldout()
  // }
}
</script>
<style lang="scss" scoped>
.round-set-numbers {
  .table {
    border: 0;

    th:first-child, td:first-child {
      border-left: 0;
    }

    th:last-child, td:last-child {
      border-right: 0;
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    td {
      font-size: 85%;

      .badge {
        font-weight: normal;
        font-size: 90%;
      }
    }

    thead {
      tr {
        th {
          font-size: 85%;
          // font-weight: normal;
          border-top: 0;
        }
      }
    }
  }
}
</style>
