class LottoHelper {

  findLotThreeNumberTop(number) {
    if (number.length !== 3 || !/^\d+$/.test(number)) {
      return null
    }

    return `lot_${number[0]}`
  }

  findLotThreeNumberTode(number) {
    if (number.length !== 3 || !/^\d+$/.test(number)) {
      return null
    }

    // กำหนดช่วงผลรวมเช่นเดิม
    const ranges = [
      { min: 0, max: 6 },
      { min: 7, max: 8 },
      { min: 9, max: 10 },
      { min: 11, max: 11 },
      { min: 12, max: 12 },
      { min: 13, max: 13 },
      { min: 14, max: 14 },
      { min: 15, max: 15 },
      { min: 16, max: 16 },
      { min: 17, max: 18 },
      { min: 19, max: 20 },
      { min: 21, max: 29 }
    ];

    // คำนวณผลรวมของตัวเลขที่ต้องการ
    const digitSum = number.split('').reduce((acc, digit) => acc + parseInt(digit), 0);

    // หาช่วงที่ผลรวมอยู่
    const lotKey = ranges.findIndex(range => digitSum >= range.min && digitSum <= range.max);

    // คืนชื่อ lot
    return `lot_${lotKey}`;
  }

  findRate(stepData, amount) {
    let rate = 0; // ค่าเริ่มต้นของ rate ถ้าไม่มี step ไหนที่ตรงเงื่อนไข

    for (let i = 0; i < stepData.length; i++) {
      if (amount >= stepData[i].minReceive) {
        rate = stepData[i].cutRate;  // ถ้า amount มากกว่าหรือเท่ากับ minReceive ให้ใช้ cutRate ของ step นั้น
      } else {
        break;  // ถ้า amount น้อยกว่า minReceive ใน step ถัดไป ก็หยุด loop ได้เลย
      }
    }

    return rate;
  }

  cutRateThreeNumberTop(round, lotHoldings, stepData, number, newAmount=0) {
    if(round?.useRateType !== 'steprate')
      return 0

    const lot = this.findLotThreeNumberTop(number)
    const amount = (lotHoldings?.[lot]?.[number] ?? 0) + newAmount
    return this.findRate(stepData, amount)
  }

  cutRateThreeNumberTode(round, lotHoldings, stepData, number, newAmount=0) {
    if(round?.useRateType !== 'steprate')
      return 0

    const lot = this.findLotThreeNumberTode(number)
    const amount = (lotHoldings?.[lot]?.[number] ?? 0) + newAmount
    return this.findRate(stepData, amount)
  }

  cutRateTwoNumber(round, lotHoldings, stepData, number, newAmount=0) {
    if(round?.useRateType !== 'steprate')
      return 0

    const lot = 'lot_0'
    const amount = (lotHoldings?.[lot]?.[number] ?? 0) + newAmount
    return this.findRate(stepData, amount)
  }

  cutRateOneNumber(round, lotHoldings, stepData, number, newAmount=0) {
    if(round?.useRateType !== 'steprate')
      return 0

    const lot = 'lot_0'
    const amount = (lotHoldings?.[lot]?.[number] ?? 0) + newAmount
    return this.findRate(stepData, amount)
  }

  cutRate(betType, round, holdings, stepDatas, number, newAmount=0) {
    return {
      'threeNumberTop': this.cutRateThreeNumberTop(round, holdings?.threeNumberTop??{}, stepDatas?.threeNumberTop??[], number, newAmount),
      'threeNumberTode': this.cutRateThreeNumberTode(round, holdings?.threeNumberTode??{}, stepDatas?.threeNumberTode??[], number, newAmount),
      'twoNumberTop': this.cutRateTwoNumber(round, holdings?.twoNumberTop??{}, stepDatas?.twoNumberTop??[], number, newAmount),
      'twoNumberBottom': this.cutRateTwoNumber(round, holdings?.twoNumberBottom??{}, stepDatas?.twoNumberBottom??[], number, newAmount),
      'runTop': this.cutRateOneNumber(round, holdings?.runTop??{}, stepDatas?.runTop??[], number, newAmount),
      'runBottom': this.cutRateOneNumber(round, holdings?.runBottom??{}, stepDatas?.runBottom??[], number, newAmount)
    }?.[betType] ?? 0
  }
}

export default new LottoHelper()
